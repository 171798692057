@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

:root {
  /* --primary: #359dfc; */
  --primary: #6448ef;
  --secondary: #592c91;
  --grey: #7787ab;
  --background: #f8f8fb;
  font-size: 14px;
}

@media screen and (max-width: 1500px) {
  :root {
    font-size: 12px;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    font-size: 10px;
  }
}

.PoppinsExtraLight {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 200 !important;
}
.PoppinsLight {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300 !important;
}
.PoppinsRegular {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}
body {
  height: 100%;
  width: 100%;
  max-height: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

#root {
  display: grid;
  place-items: center;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.app {
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  background: var(--background);
}

.all-app {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-height: 100%;
}

.scg-dialog {
  width: 600px;
  max-width: 100%;
  text-align: center;
  padding: 1em;
}

.scg-dialog-title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0;
  padding: 1rem;
}

.scg-dialog-actions {
  display: flex !important;
  justify-content: space-evenly !important;
}
.home-screen-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.main-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
}

.my-confs-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1450px;
  padding-block: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}

/* MY Confs */
.myConfsScreenContainer {
  padding: 1rem;
  margin-inline: auto;
  /* margin-top: 4rem; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myConfsTopBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2em;
}

.conf-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2em;
  column-gap: 2em;
  padding: 1rem;
  width: 100%;
  max-width: 1500px;
}

@media screen and (max-width: 800px) {
  .conf-container {
    grid-template-columns: repeat(2, 18rem);
    place-items: center;
  }
}

@media screen and (max-width: 530px) {
  .conf-container {
    grid-template-columns: repeat(1, 15rem);
  }
}
.conf-card {
  background-color: rgb(204, 204, 204);
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 270px;
  height: 320px;
  box-shadow: 3px 3px 10px #6448ef14;
  border-radius: 9px;
  position: relative;
}

.add-conf-card {
  background-color: rgb(204, 204, 204);
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 270px;
  height: 320px;
  border: 1px solid var(--primary);
  border-radius: 12px;
  position: relative;
}

.conf-card > .actions {
  position: absolute;
  top: 1.7em;
  right: -1em;
}

.db-dns-confirm {
  background: rgb(127, 127, 255);
  padding: 1rem;
  border-radius: 30px;
  color: aliceblue;
}

.db-dns-success {
  background: rgb(62 155 66);
}

.db-dns-failed {
  background: rgb(155, 62, 62);
  padding: 1rem;
  border-radius: 30px;
  color: aliceblue;
}

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.place-items-center {
  display: grid;
  place-items: center;
}

.form-container {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1rem;
  border-radius: 30px;
  /* background: #bcd568; */
}

.create-new-conference-title {
  margin: 0;
  text-transform: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  color: black;
}

.shadowed {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

#explore-and-learn-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: white; */
  padding-block: 1em;
  padding-inline: 10em;
  margin-left: auto;
  width: 100%;
  /* max-width: 500px; */
  gap: 2em;
  overflow-y: auto;
  /* min-height: 100%; */
}

/* @media screen and (max-width: 1100px) {
  .form-container {
    width: 70%;
  }
}
@media screen and (max-width: 900px) {
  .form-container {
    width: 80%;
  }
} */

@media screen and (max-width: 400px) {
  .buttons-container {
    flex-direction: column;
    gap: 1rem;
  }
}

#dropdownLanguageSelection {
  background-color: transparent;
  margin-left: auto;
}

@media screen and (max-width: 500px) {
  #appTitle {
    display: none;
  }

  .form-container {
    width: 90%;
  }

  .create-new-conference-title {
    font-size: 1.25rem;
  }
}

/* BILLING */
.billingItemCard {
  padding: 1em;
  width: fit-content;
}

.billingHistoryContainer {
  padding-block: 2em;
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  margin-top: 4rem;
}

.profile-form-container {
  max-width: 600px;
  width: 90%;
  background: #ffffff;
  box-shadow: 0px 0px 34px #5348ef1f;
  padding: 3.2em 4.5em;
  border-radius: 12px;
}

.scg-success-message,
.successMessage {
  background: #defedd;
  color: green;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
}

.scg-error-message,
.errorMessage {
  background: #fedddd;
  color: red;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
}

.scg-warning-message,
.warningMessage {
  background: #feeddd;
  color: #ff5a19;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  height: fit-content;
}

#searchbar {
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 2em; */
}

/* #searchbar > div > div {
  padding: 1em;
} */

.top-popup {
  /* make that be on top of screen */
  display: flex;
  flex-direction: space-between;
  z-index: 1000;
  top: 0;
  background-color: var(--primary);
  color: white;
  padding: 1em;
  text-align: center;
  width: 100%;
  font-size: 1.2em;
}

.top-popup > a {
  color: white;
}

.popup-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  margin-left: 1em;
}

.my-row {
  display: flex;
  flex-direction: row;
}

.gap1 {
  gap: 1rem;
}

/* when portarait change class */
@media screen and (orientation: portrait) {
  .home-screen-container {
    flex-direction: column;
  }

  #explore-and-learn-component {
    overflow-y: visible;
    width: 100%;
    max-width: 100% !important;
  }
}
