@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.sidebar {
  /* border: 1px solid #000; */
  background-color: #fafafc;
  opacity: 1;
  color: #7787ab;
  box-shadow: 10px 0px 40px #6448ef1a;
  display: flex;
  flex-direction: column;
  padding: 1em;
  /* cursor: pointer; */
  /* IT WORKS THAT WAY */
  overflow-x: hidden;
}

.open {
  width: 330px;
}

.closed {
  width: 70px;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid blue; */
}

.popover {
  min-width: 200px;
  padding: 1em;
  gap: 1em;
  cursor: pointer;
}

.menuItem {
  /* font-size: 1.2rem; */
  /* padding-left: 0.5em; */
  padding-inline: 1em;
  padding-block: 0.5em;
  color: #7787ab;
  cursor: pointer;
}
/* .menuItem :hover{
  
} */

.menuItem > p > p {
  font-size: 1rem;
}

.menuItemActive {
  background: #6448ef2b;
  border-radius: 8px;
  color: var(--primary);
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}
.menuItemActive > div > p {
  color: var(--primary);
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}
/* .menuItemActive > button {
  color: var(--primary);
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
} */

.submenuItem {
  padding-left: 2.7rem;
  color: #a4afc9;
}

.submenuItem > div > p {
  font-size: 0.9rem;
  color: #a4afc9;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}

.submenuItemActive > div > p {
  color: var(--primary);
}
.submenuItemActive > div > p {
  color: var(--primary);
}
/* .menuIcon {
  font-size: 45rem;
} */

.sidebarHeader {
  display: flex;
  flex-direction: column;
}
