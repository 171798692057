.container {
  display: grid;
  place-items: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(40px);
}

.modal {
  position: fixed;
  top: 15vh;
  display: flex;
  flex-direction: column;
  left: 5%;
  margin-inline: auto;
  width: 90%;
  max-height: 80%;
  height: fit-content;
  z-index: 100;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 1em 1em 1em 1em;
  transition: padding 3s ease-in-out 1s;
}

.modalTitle {
  text-align: center;
  color: var(--primary);
  font-size: 2.2rem;
}

.modalFormContainer {
  max-width: 280px;
  width: 85%;
  display: flex;
  margin-inline: auto;
  /* padding-block: 3.5em; */
}
.modalResetFormContainer {
  max-width: fit-content;
  width: 85%;
  display: flex;
  margin-inline: auto;
  padding-block: 3.5em;
}
.forgotPassword {
  padding-top: 0px;
  margin-left: auto;
  margin-bottom: 50px;
}
.privacyPolicy {
  font-size: 0.8rem;
}
.alternativeText {
  font-size: 0.8rem;
}

@media screen and (min-width: 600px) {
  .modal {
    max-width: 530px;
    left: calc(50% - 265px);
  }
}
