@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

.LandingPageScreen {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  height: 100vh;
  /* background: #e7e7ee 0% 0% no-repeat padding-box; */
  background-size: cover;
  background-position: center;
}

.TextContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  background: #f3f3f3;
  width: 100%;
  height: 100%;
  border-radius: 0 30px 30px 0;
  /* padding: 0 10% 0 10%; */
  box-shadow: 20px 0px 10px #6e6ed50d;
  z-index: 2;
}
.welcomeMessageContainer {
  text-align: center;
  line-height: 4rem;
  font-family: 'Poppins', sans-serif;
  font-size: 3.75rem;
  margin-top: auto;
  color: #6d6d6d;
}
.ePostersLiveContainer {
  text-align: center;
  line-height: 3rem;
  font-family: 'Poppins', sans-serif;
  font-size: 4.75rem;
  color: #6448ef;
}

.ImageContainer {
  display: grid;
  place-items: center;
  /* padding-left: 55rem; */
  /* width: 100%;
  height: 100%; */

  /* max-width: 35rem; */
  width: 100%;
  height: auto;
}

.sectionImage {
  width: 100%;
  min-width: 200px;
  height: auto;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  /* padding-block: 2rem; */
  font-size: 1.75rem;
  /* color: var(--primary); */
}
.img {
  max-width: 100%;
}

@media (orientation: portrait) {
  .LandingPageScreen {
    flex-direction: column-reverse;
  }

  .TextContainer {
    border-radius: 70px 70px 0 0;
  }

  .ImageContainer {
    height: 50%;
  }

  .img {
    max-height: 70%;
  }

  .welcomeMessageContainer {
    font-size: 2.5rem;
  }

  .ePostersLiveContainer {
    font-size: 3rem;
  }
  .descriptionContainer {
    font-size: 1.25rem;
  }
}

.one {
  /* place-items: center; */
  display: grid;
  place-items: center;
  color: white;
  min-width: 40%;
}
.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.title {
  font-size: 20rem;
}
.image {
  width: 100%;
  height: 100%;
}
.imageContainer {
  display: grid;
  place-items: center;
  /* background: #000000; */
}
