.toolbarStart {
  display: flex;
  align-items: center;
  justify-content: end;
}

.toolbarMiddle {
  width: fit-content;
}

.Sidebar {
  height: 100%;
  background: var(--primary);
  width: 100%;
  padding: 1em 1em 0 0 !important;
}
.SidebarItem {
  background: #592c91;
  color: white !important;
  width: 90% !important;
  margin: 1em 0.5em 1em 0;
  padding: 1em 2em;
  border-radius: 0 15px 15px 0;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
}
.SidebarItem:hover {
  width: 100% !important;
  transition: 150ms;
}

.SidebarIcon {
  color: #ffffff !important;
}

@media (orientation: portrait) {
  .Sidebar {
    width: 100vw !important;
  }
  .SidebarItem {
    min-width: fit-content !important;
    width: 50% !important;
    margin: 0.5em auto;
    border-radius: 15px;
  }
  .Sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    padding: 2rem !important;
  }
}

.posterGridContainer {
  display: grid;
  place-items: center;
}
.posterGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 3.5rem;
  width: fit-content;
}

.posterGridContainerList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
