.tourContent {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.tourTitle {
  color: var(--primary);
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 25px;
}

.tourText {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
}

.customBackButton {
  position: absolute;
  bottom: 14px;
  left: 155px;
  padding: 0.6em;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 4px;
  width: 107px;
}

.customSkipButton {
  padding: 0.5em;
  position: absolute;
  top: 5px;
  right: 5px;
}

@media screen and (max-width: 600px) {
  .customBackButton {
    left: 8rem;
  }
}
