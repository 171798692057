.titleContainer {
  padding-block: 1em;
}

.videoTutorialsContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.articlesAndDocsContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.faqContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
