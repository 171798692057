.HelpCenterContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 4em;
  gap: 5em;
  margin-top: 4.5rem;
  background: #ffffff;
  box-shadow: 3px 2px 7px #9487d02e;
  min-height: 500px;
}

.helpCenterTitleContainer {
  text-align: center;
  margin-top: 4rem;
}

.submenuContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 1em;
  max-width: 30rem;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  padding: 1em;
  max-width: 50rem;
  min-width: 35rem;
}

.card {
  padding-inline: 2em;
  padding-block: 1.5em;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-height: 217px;
  border-radius: 8px;
  max-width: 40rem;
}

.pointer {
  cursor: pointer;
}

.leftSubtitle,
.leftSubtitleActive {
  padding-left: 1rem;
  cursor: pointer;
}

.leftSubtitleActive {
  border-left: 3px solid var(--primary);
}

.cardWithSubtitles {
  padding-inline: 2em;
  padding-block: 1.5em;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* min-height: 217px; */
  border-radius: 8px;
  box-shadow: 3px 3px 10px #6448ef0f;
  background: #ffffff;
  max-width: 30rem;
  cursor: pointer;
}

.divider {
  width: 80px;
  margin-block: 30px;
  margin-left: 1rem;
}
