.header {
  top: 0px;
  position: sticky;
  max-height: 80px;
  min-width: 100%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  gap: 30rem;
}

.navbarItems {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}

.navbarItem {
  font-size: 1rem !important;
  font-family: 'Poppins', sans-serif !important ;
  color: var(--primary);
}

.mobileDropdown {
  display: none;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 3rem;
  gap: 1rem;
  padding-block: 2em;
  min-width: 200px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.vrline {
  border-right: 1px solid #b5a9f2 !important;
  opacity: 1;
  height: 20px;
  color: var(--primary);
}

.landingPage {
  /* background: transparent url('../icons/LandingPage/landingPageBackground.png') 0% 0% no-repeat padding-box; */
  /* opacity: 0.27; */
  width: 100%;
}

.landingPageContainer > div:not(.effortlessSectionContainer) {
}

.sectionImageContainer {
  max-width: 35rem;
  /* width: 35%; */
}

.sectionImage {
  width: 100%;
  min-width: 200px;
  height: auto;
}

.generalSectionTitle {
  font-size: 3rem !important;
  font-family: 'Poppins', sans-serif !important ;
  font-weight: 500 !important;
  color: #687694;
}

.heroSectionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10rem;
  margin-block: 10rem;
}

.heroSectionLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  max-width: 42rem;
  gap: 2em;
}

.heroSectionTitlePrimary {
  font-size: 3rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important ;
  color: var(--primary);
}

.heroTitleNewLine {
  display: none;
}

.heroSectionTitleSecondary {
  font-size: 3rem !important;
  font-family: 'Poppins', sans-serif !important ;
  color: #687694;
}

.heroSectionSubtitle {
  font-size: 1.3rem !important;
  font-family: 'Poppins', sans-serif !important ;
  color: #687694;
}

.startButton {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif !important ;
  border-radius: 8px;
  padding-inline: 2em;
}

.videoSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5em;
  flex-wrap: wrap;

  height: fit-content;
  padding-block: 2em;
  margin-bottom: 10em;

  background: #6448ef 0% 0% no-repeat padding-box;
  box-shadow: 0px -5px 10px #68769467;
}

.videoSectionText {
  max-width: 42rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
}

.videoContainer {
  max-width: 42rem;
}

.featuresSectionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2em;
  margin-bottom: 10em;
  align-items: center;
}

.designCardsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2em;
  max-width: 600px;
}

.designCard {
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.designCardIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  margin-bottom: 2em;
}

.designCardIconContainer img {
  width: 80px;
  height: 105px;
}

.designCardIconText {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important ;
  color: var(--primary);
}

.designCardSubtitle {
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important ;
  color: #687694;
}

.effortlessSectionContainer {
  /* height: 60rem; */
  height: fit-content;
  padding-block: 20em;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 5em;
  margin-bottom: 10em;
  padding-inline: 36rem;

  background: var(--primary-color) 0% 0% no-repeat padding-box;
  background: transparent url('../icons/LandingPage/uniBG.png') 50% 50% no-repeat padding-box;
  background-size: 110%;

  /* background: #6448ef 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px -5px 10px #68769467; */
}

.effortlessSectionText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
}

.essentialFeaturesSectionTitle {
  display: flex;
  flex-direction: column;
  justify-content: right;
  gap: 1em;
  max-width: 50rem;
  text-align: right;
}

.effortlessSectionTitle {
  font-size: 3rem !important;
  font-family: 'Poppins', sans-serif !important ;
  font-weight: 500 !important;
  color: #ffffff;
}

.effortlessSectionSubtitleContainer {
  display: flex;
  justify-content: flex-start;
}

.effortlessSectionSubtitle {
  width: 50%;
  font-size: 1rem !important;
  font-family: 'Poppins', sans-serif !important ;
  color: #ffffff;
}

.boldText {
  font-weight: 500;
}

.supportSectionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10rem;
  margin-bottom: 10em;
}

.supportSectionTitle {
  font-size: 3rem !important;
  font-family: 'Poppins', sans-serif !important ;
  color: #687694;
}

.supportSectionTextContainer {
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
}

.pricingSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10em;
  margin-bottom: 10em;
}

.pricingSectionTitle {
  font-size: 3rem !important;
  font-family: 'Poppins', sans-serif !important ;
  font-weight: 500 !important;
  padding-inline: 36rem;
  color: #687694;
}

.pricingCardsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}

.pricingCardsFooterText {
  font-size: 2rem !important;
  font-family: 'Poppins', sans-serif !important ;
  font-weight: 400 !important;
  text-align: center !important;
  padding-inline: 1em;
}

.pricingCard {
  width: 250px;
  height: 470px;
  display: grid;
  grid-template-rows: 5fr 1fr 3fr;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 2em;
  padding: 45px 30px;

  border-radius: 40px;
  -webkit-box-shadow: 0 0 59px rgba(191, 191, 191, 0.16);
  background: #fffffff7 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #6448ef0d;
  border-radius: 11px;
}

.hrline {
  border-right: 1px solid #d3d1e1 !important;
  opacity: 1;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em auto;
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--primary);
}

.hrlineHeader {
  border-right: 1px solid #d3d1e1 !important;
  opacity: 1;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em auto;
  width: 100px;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--primary);
}

.pricingFeaturesContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding-left: 1em;
  gap: 1em;
}

.pricingFeature {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.pricingPlanTitle {
  font-size: 2rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important ;
  color: var(--primary);
}

.pricingPlanPerYear {
  font-size: rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important ;
  /* color: #687694; */
}

.pricingPlanSubtitle {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important ;
  color: #687694;
}

.footerSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* width: 100%;
  height: 50px; */
  padding-top: 10em;
  padding-inline: 20rem;
  background: transparent url('../icons/LandingPage/footerBG.png') 20% 50% no-repeat padding-box;
}

.footerContainer {
  display: flex;
  justify-content: center;
  gap: 20rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.footerContentCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
  max-width: 35rem;
}

.footerInfoCols {
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.socialMediaIcons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.socialMediaIcons img {
  width: 40px;
  height: auto;
}
.logoImageContainer {
  cursor: pointer;
}

/* Media query for small screens */
@media only screen and (max-width: 1900px) {
  .footerContainer {
    gap: 5rem;
  }

  .effortlessSectionContainer {
    padding-inline: 20rem;
  }

  .pricingSectionTitle {
    padding-inline: 20rem;
  }
}

@media only screen and (max-width: 1400px) {
  .featuresSectionContainer {
    flex-direction: column-reverse;
    text-align: center !important;
  }

  .essentialFeaturesSectionTitle {
    text-align: center !important;
  }

  .videoSectionText {
    justify-content: center !important;
    text-align: center !important;
  }

  .generalSectionTitle {
    text-align: center !important;
  }

  .footerContainer {
    gap: 2em;
  }
}

@media only screen and (max-width: 1250px) {
  .navbarItems {
    display: none;
  }

  .mobileDropdown {
    display: block;
  }

  .header {
    padding-inline: 1em;
    gap: 10rem;
  }

  .landingPageContainer > div:not(.effortlessSectionContainer) {
    padding-inline: 1em;
  }

  .heroSectionContainer {
    gap: 2em;
    text-align: center !important;
    align-items: center !important;
  }

  .heroSectionLeftContainer {
    align-items: center !important;
  }

  /* .generalSectionTitle {
    text-align: center !important;
  } */

  .designCardsContainer {
    justify-content: center;
  }

  .effortlessSectionContainer {
    padding-inline: 1em;
  }

  .effortlessSectionTitle {
    text-align: center !important;
  }

  .effortlessSectionSubtitleContainer {
    justify-content: center;
  }

  .effortlessSectionSubtitle {
    text-align: center !important;
  }

  .supportSectionContainer {
    justify-content: center;
  }

  .footerSection {
    padding-inline: 1em;
    gap: 2em;
  }
}

@media only screen and (max-width: 900px) {
  .logoImageContainer {
    width: 20rem;
  }

  .pricingSectionTitle {
    padding-inline: 1rem;
    text-align: center !important;
  }

  .heroSectionContainer {
    justify-content: center;
  }

  .heroSectionTitleContainer {
    line-height: 1 !important;
  }

  .heroTitleNewLine {
    display: block;
  }

  .effortlessSectionContainer {
    padding-block: 10em;
    background: transparent url('../icons/LandingPage/uniBG.png') 70% 50% no-repeat padding-box;
  }

  .videoSectionContainer {
    padding-inline: 15rem;
  }

  .supportSectionContainer {
    justify-content: center;
  }
  .supportSectionTitle {
    text-align: center !important;
  }
  .supportSectionTextContainer {
    justify-content: center !important;
    text-align: center !important;
  }

  .footerContainer {
    gap: 2em;
  }
}
