.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 15vh;
  left: 10%;
  width: 80%;
  max-height: 80%;
  z-index: 100;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 0 1rem;
  transition: padding 3s ease-in-out 1s;
  padding: 1rem;
}
.modalInternalRelativeContainer {
  position: relative;
}

.header > h2 {
  background: #359dfc;
  color: #ffffff;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #359dfc;
  padding: 1rem;
  align-items: baseline;
}
.content {
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

@media screen and (max-width: 530px) {
  .modal {
    top: 8vh;
    left: 5%;
    width: 90%;
    padding: 0.5rem;
    max-height: 100%;
  }
}
